import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby"
import { FaTag } from "react-icons/fa/";
import _JSXStyle from "styled-jsx/style";

const Tags = props => {
  const { tags, theme } = props;

  return (
    <React.Fragment>
      {tags && (
        <div className="tags">
          {
            tags.map( tag => {
              const tagLink = `/tag/${tag}`;
              return (
                <Link to={tagLink} key={tag}>
                  <span>
                    <FaTag size={14} /> {tag}
                  </span>
                </Link>
              )
            })
          }
        </div>
      )}

      {/* --- STYLES --- */}
      <style jsx>{`
        .tags {
          margin: ${theme.space.l} 0;
          padding: ${theme.space.l} 0;
          border-top: 1px solid ${theme.line.color};
          border-bottom: 1px solid ${theme.line.color};
        }
        .tags span {
          margin-right: ${theme.space.m};
          padding: ${theme.space.xs} 0;
          font-size: ${theme.font.size.s};
          line-height: ${theme.font.lineHeight.l};
        }
        :global(svg) {
          fill: ${theme.icon.color};
          margin: ${theme.space.inline.xs};
        }
      `}</style>
    </React.Fragment>
  );
};

Tags.propTypes = {
  tags: PropTypes.array.isRequired,
  theme: PropTypes.object.isRequired
};

export default Tags;
